import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {BarChart, Bar, YAxis, Tooltip, Legend} from "recharts";



const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let list = [];
    for (let i=0; i<payload.length-1; i++) {
      list.push(
        <p style={{color:payload[i].fill}}>{payload[i].dataKey}: {payload[i].value.toFixed(0)} % of total</p>
      );
    }
    return (
      <div className="custom-tooltip">
        {list}
        {/* <p className="label">{`VO2 Max: ${vo2Points} % of total`}</p>
        <p className="label">{`Strength: ${strengthPoints} % of total`}</p>
        <p className="label">{`Smoking: ${smokingPoints} % of total`}</p>
        <p className="label">{`Social: ${socialPoints} % of total`}</p>
        <p className="label">{`BP: ${bpPoints} % of total`}</p> */}
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

const RangeSlider = (props) => {
  const percentage = 100 * (props.value - props.min) / (props.max - props.min)
  const rangerStyle = {
     background: `linear-gradient(90deg, var( - primary-600) 0, var( - orange-500) ${percentage}%, ${props.secondaryBgColor ? props.secondaryBgColor : 'var( - defaut-color)'} ${percentage + 0.1}%)`
   }

return (
 <input className={`range-slider-input ${props.customClasses ? props.customClasses : ''}`} style={rangerStyle} type='range' value={props.value} min={props.min} max={props.max} onChange={props.onChange} disabled={props.disabled} />
 )

}

const LongevityDashboard = (props) => {
  const vo2Color = '#A7F2CF';
  const strengthColor = '#479393';
  const smokingColor = '#ECA282';
  const socialColor = '#5465FF';
  const bpColor = '#990D35';
  const bgColor = 'lightgrey';
  const v = parseFloat(props.inputData.vo2Percent.toFixed(2));
  const str = parseFloat(props.inputData.strengthPercent.toFixed(2));
  const smo = parseFloat(props.inputData.smokingPercent.toFixed(2));
  const soc = parseFloat(props.inputData.socialPercent.toFixed(2));
  const bpp = parseFloat(props.inputData.bpPercent.toFixed(2));

  const vo2Maxpoints = 504;
  const strengthMaxPoints = 300;
  const smokingMaxPoints = 180;
  const socialMaxPoints = 150;
  const bpMaxPoints = 115;

  const totalPossiblePoints = vo2Maxpoints + strengthMaxPoints + smokingMaxPoints + socialMaxPoints + bpMaxPoints;
  const patientTotalPoints = v * vo2Maxpoints + str * strengthMaxPoints + smo * smokingMaxPoints + soc * socialMaxPoints + bpp * bpMaxPoints;
  const totalLongevityPercentage = Math.round((patientTotalPoints / totalPossiblePoints )* 100)/1;

  let data = [
    {
      name: "Total Longevity",
      VO2: (v*vo2Maxpoints/totalPossiblePoints) * 100,
      Strength: (str*strengthMaxPoints/totalPossiblePoints) * 100,
      Smoking: (smo*smokingMaxPoints/totalPossiblePoints) * 100,
      Social: (soc*socialMaxPoints/totalPossiblePoints) * 100,
      BP: (bpp*bpMaxPoints/totalPossiblePoints) * 100,
      Fill: 100 - ((v*vo2Maxpoints/totalPossiblePoints) * 100 + (str*strengthMaxPoints/totalPossiblePoints) * 100 + (smo*smokingMaxPoints/totalPossiblePoints) * 100 + (soc*socialMaxPoints/totalPossiblePoints) * 100 + (bpp*bpMaxPoints/totalPossiblePoints) * 100),
    },
  ];

  return (
    <div className='LongevityDashboard' id='LongevityDashboard'>
      <div className='combo-chart-div'>
        <div className='info-dot'><p>i</p></div>
        <h1 className='finalResult' id='finalResult'>Potential Longevity Claimed: {totalLongevityPercentage}%</h1>
        <BarChart id='combined-barchart'
          width={150}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis dataKey="name" /> */}
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="VO2" stackId="dashboardComboStack" fill={vo2Color} />
          <Bar dataKey="Strength" stackId="dashboardComboStack" fill={strengthColor} />
          <Bar dataKey="Smoking" stackId="dashboardComboStack" fill={smokingColor} />
          <Bar dataKey="Social" stackId="dashboardComboStack" fill={socialColor} />
          <Bar dataKey="BP" stackId="dashboardComboStack" fill={bpColor} />
          <Bar dataKey="Fill" stackId="dashboardComboStack" fill={bgColor} />
        </BarChart>
      </div>
      <div className='input-ranges-div'>
        <div className='range container'>
          <div className='range div' id='vo2-range-div'>
          <h2>VO2 Max</h2>
            <RangeSlider
              min='0'
              max='100'
              value={v*100}
              disabled={true}
              // onChange={(e) => { setVo2(+e.target.value) }}
              customClasses='vo2 range input'
            /> 
            <p>{v*100}% of possible</p>
          </div>
          <p className='category info'>{props.inputData.vo2Category}</p>
        </div>
        <div className='range container'>
          <div className='range div' id='strength-range-div'>
          <h2>Strength</h2>
            <RangeSlider
              min='0'
              max='100'
              value={str*100}
              disabled={true}
              // onChange={(e) => { setStrength(+e.target.value) }}
              customClasses='strength range input'
            /> 
            <p>{str*100}% of possible</p>
          </div>
          <p className='category info'>{props.inputData.strengthCategory}</p>
        </div>
        <div className='range container'>
          <div className='range div' id='smoking-range-div'>
          <h2>Smoking</h2>
            <RangeSlider
              min='0'
              max='100'
              value={smo*100}
              // step="1"
              disabled={true}
              // onChange={(e) => { setSmoking(+e.target.value) }}
              customClasses='smoking range input'
            /> 
            <p>
              {smo*100}% of possible
            </p>
          </div>
          <p className='category info'>{props.inputData.smokingCategory}</p>
        </div>
        <div className='range container'>
          <div className='range div' id='social-range-div'>
            <h2>Social<br />Connection</h2>
            <RangeSlider
              min='1'
              max='100'
              // step='1'
              value={soc*100}
              disabled={true}
              // onChange={(e) => { setSocial(+e.target.value) }}
              customClasses='social range input'
            /> 
            <p>{soc*100}% of possible</p>
          </div>
          <p className='category info'>{props.inputData.socialCategory}</p>
        </div>
        <div className='range container'>
          <div className='range div' id='bp-range-div'>
            <h2>Blood<br />Pressure</h2>
            <RangeSlider
              min='0'
              max='100'
              // step='1'
              value={bpp*100}
              disabled={true}
              // onChange={(e) => { setBp(+e.target.value) }}
              customClasses='bp range input'
            /> 
            <p>{bpp*100}% of possible</p>
          </div>
          <p className='category info'>{props.inputData.bpCategory}</p>
        </div>
      </div>
    </div>
  );
}

export default LongevityDashboard;

