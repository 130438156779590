import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import LongevityDashboard from '../../components/longevity-dashboard';
import ComponentGraph from '../../components/component-graph';

const RangeSlider = (props) => {
  const percentage = 100 * (props.value - props.min) / (props.max - props.min)
  const rangerStyle = {
     background: `linear-gradient(90deg, var( - primary-600) 0, var( - orange-500) ${percentage}%, ${props.secondaryBgColor ? props.secondaryBgColor : 'var( - defaut-color)'} ${percentage + 0.1}%)`
   }

return (
 <input className={`range-slider-input ${props.customClasses ? props.customClasses : ''}`} step={props.step} style={rangerStyle} type='range' value={props.value} min={props.min} max={props.max} onChange={props.onChange} disabled={props.disabled} />
 )

}

const LongevityApp = () => {

  const [age, setAge] = useState(30);
  const [gender, setGender] = useState('male');
  const [vo2, setVo2] = useState(0);
  const [gripDyno, setGripDyno] = useState(0);
  const [kneeDyno, setKneeDyno] = useState(0);
  const [legExtension, setLegExtension] = useState(0);
  const [legMomentArm, setLegMomentArm] = useState(34);
  const [squat, setSquat] = useState('');
  const [bench, setBench] = useState('');


  const [smokingFreq, setSmokingFreq] = useState('1d');
  const [socialScore, setSocialScore] = useState({
    married: false,
    nuclearFam: false,
    extendedFam: false,
    friends: false,
    organizations: false,
  });
  const [sbp, setSbp] = useState(130);
  const [dbp, setDbp] = useState(90);

  const prevVo2 = useRef();
  const prevAge = useRef();
  const prevGripDyno = useRef();
  const prevKneeDyno = useRef();
  const prevLegExtension = useRef();
  const prevLegMomentArm = useRef();
  const prevSquat = useRef();
  const prevBench = useRef();
  const prevGender = useRef();
  const prevSbp = useRef();
  const prevDbp = useRef();
  const prevSmokingFreq = useRef();
  const prevSocialScore = useRef();


  const [vo2Category, setVo2Category] = useState('Good');
  const [strengthCategory, setStrengthCategory] = useState('Good');
  const [smokingCategory, setSmokingCategory] = useState('2ppd');
  const [socialCategory, setSocialCategory] = useState('Extremely Isolated');
  const [bpCategory, setBpCategory] = useState('Pre-Hypertension');

  const [vo2Points, setVo2Points] = useState(275);
  const [strengthPoints, setStrengthPoints] = useState(212);
  const [smokingPoints, setSmokingPoints] = useState(100);
  const [socialPoints, setSocialPoints] = useState(100);
  const [bpPoints, setBpPoints] = useState(100);
  // const [totalLongevityPercentage, setTotalLongevityPercentage] = useState(50);

  const vo2Maxpoints = 504;
  const strengthMaxPoints = 300;
  const smokingMaxPoints = 180;
  const socialMaxPoints = 150;
  const bpMaxPoints = 115;

  const totalPossiblePoints = vo2Maxpoints + strengthMaxPoints + smokingMaxPoints + socialMaxPoints + bpMaxPoints;
  const patientTotalPoints = vo2Points + strengthPoints + smokingPoints + socialPoints + bpPoints;
  const totalLongevityPercentage = Math.round((patientTotalPoints / totalPossiblePoints)* 1000)/10;

  const [vo2GraphData, setVo2GraphData] = useState([]);
  const [strengthGraphData, setStrengthGraphData] = useState([]);
  const [smokingGraphData, setSmokingGraphData] = useState([]);
  const [socialGraphData, setSocialGraphData] = useState([]);
  const [bpGraphData, setBpGraphData] = useState([]);

  useEffect(() => {
    if ((prevAge.current != age) || (prevGender.current != gender)) {
      calcVo2Points();
      calcStrengthPoints();
    }
    else if ( prevVo2.current != vo2) {
      calcVo2Points();
    }
    else if ( 
      (prevGripDyno.current != gripDyno) ||
      (prevKneeDyno.current != kneeDyno) ||
      (prevLegExtension.current != legExtension) ||
      (prevSquat.current != squat) ||
      (prevBench.current != bench) ||
      (prevLegMomentArm.current != legMomentArm)
    ) {
      calcStrengthPoints();
    }
    else if ( (prevSbp.current != sbp) || prevDbp.current != dbp) {
      calcBpPoints();
    }
    else if (prevSmokingFreq.current != smokingFreq) {
      calcSmokingPoints();
    }
    else if (prevSocialScore.current != socialScore) {
      calcSocialPoints();
    }
    prevDbp.current = dbp;
    prevSbp.current = sbp;
    prevBench.current = bench;
    prevSquat.current = squat;
    prevLegExtension.current = legExtension;
    prevLegMomentArm.current = legMomentArm;
    prevKneeDyno.current = kneeDyno;
    prevGripDyno.current = gripDyno;
    prevVo2.current = vo2;
    prevAge.current = age;
    prevGender.current = gender;
    prevSmokingFreq.current = smokingFreq;
    prevSocialScore.current = socialScore;
    
  }, [age, gender, vo2, gripDyno, kneeDyno, legExtension,legMomentArm, squat, bench, smokingFreq, socialScore, sbp, dbp]);

  const exponentialRenormalize = (alpha, beta, omega, theta, val) => {
    const a = (omega - theta) / (Math.log10( (alpha+1) / (beta+1) ));
    const d = omega - a * Math.log10(alpha + 1);
    const r = a * Math.log10( val + 1 ) + d;
    return r;
  }

  const calcVo2Points = (v, isGraphPoint = false) => {
    console.log('strat');
    if (!isGraphPoint) {
      console.log('test');
      v=vo2;
    }

    const maleNorms = [
      [29.01, 32.1, 40.1, 48.01, 55.2, 61.8, 66.3],
      [27.2, 30.2, 35.9, 42.4, 49.2, 56.5, 59.8],
      [24.2, 26.8, 31.9, 37.8, 45.01, 52.1, 55.6],
      [20.9, 22.8, 27.1, 32.6, 39.7, 45.6, 50.7],
      [17.4, 19.8, 23.7, 28.2, 34.5, 40.3, 43.01],
      [16.3, 17.1, 20.4, 24.4, 30.4, 36.6, 39.7]
    ];
    const femaleNorms = [
      [21.7, 23.9, 30.5, 37.6, 44.7, 51.3, 56.01],
      [19.01, 20.9, 25.3, 30.2, 36.1, 41.4, 45.8],
      [17.01, 18.8, 22.1, 26.7, 32.4, 38.4, 41.7],
      [16.01, 17.3, 19.9, 23.4, 27.6, 32.01, 35.9],
      [13.4, 14.6, 17.2, 20.01, 23.8, 27.01, 29.4],
      [13.1, 13.6, 15.6, 18.3, 20.8, 23.1, 24.1]
    ];
  
    let ageGroup = 0;
    let category = vo2 + 'ml/min/kg, ';
    category='';
    let points = 275;

    if (age <= 29) ageGroup = 0;
    else if (age <= 39) ageGroup = 1;
    else if (age <= 49) ageGroup = 2;
    else if (age <= 59) ageGroup = 3;
    else if (age <= 69) ageGroup = 4;
    else if (age <= 79) ageGroup = 5;
    else ageGroup = 5;

    const norm = (gender === 'male') ? maleNorms[ageGroup] : femaleNorms[ageGroup];
  
    if (v < norm[0]) {
      points = exponentialRenormalize(0, norm[0], 50, 100, v);
      category += 'Very Poor (< 5th percentile for age and gender)';
    } 
    else if (v < norm[2]) {
      points = exponentialRenormalize(norm[0], norm[2], 100, 195, v);
      if (v < norm[1]) category += 'Poor (5th-10th percentile for age and gender)';
      else if (v < norm[2]) category += 'Mediocre (10th-25th percentile for age and gender)';
    }
    else if (v < norm[3]) {
      points = exponentialRenormalize(norm[2], norm[3], 195, 275, v);
      category += 'Good (25th-50th percentile for age and gender)';
    }
    else if (v < norm[4]){
      points = exponentialRenormalize(norm[3], norm[4], 275, 399, v);
      category += 'Very Good (50th-75th percentile for age and gender)';
    } 
    else if (v < norm[6]){
      points = exponentialRenormalize(norm[4], norm[6], 399, 495, v);
      if (v < norm[5]) category += 'Excellent (75th-90th percentile for age and gender)';
      else category += 'Superior (90th-95th percentile for age and gender)';
    } 
    else if (v >= norm[6]){
      points = exponentialRenormalize(norm[6],norm[6] + (norm[6]-norm[5]) * .8, 495, 504, v);
      category = 'Incredible! (> 95th percentile for age and gender)';
    } 
    if (points > 504) points = 504;
    
    points = parseFloat(points.toFixed(1));
    
    if (isGraphPoint) return points;

    let graphData = [];
    for (let i=0; i < norm.length; i++) {
      let val = norm[i];
      let p = calcVo2Points(val, true);
      graphData.push( {
        points: p,
        value: val,
        current: false
      });
    }
    graphData.push( {
      points: Math.round(calcVo2Points(norm[6] + ((norm[6]-norm[5])*1.1), true)),
      value: Math.round(norm[6] + ((norm[6]-norm[5])*1.1)),
      current: false
    });
    graphData.push({
      points: points,
      value: Math.round(vo2),
      current: true
    });

    setVo2GraphData(graphData.sort( (a,b) => {
      return (a.value - b.value);
    }));

    setVo2Points(points);
    setVo2Category(category);
  
  }

  const calcStrengthPoints = (u, l, isGraphPoint = false) => {
    if (! isGraphPoint) {
      u=gripDyno;
      l=kneeDyno;
    }
    let points = 100;
    let gripPoints = 100;
    let legPoints = 100;
    let category = 'Adequate';

    if (u) {
      const maleNorms = [30,40,50];
      const malePoints = [100, 225, 262.5, 300]
      const femaleNorms = [20,25,30];
      const femalePoints = [100, 233.3, 300, 300]
      const norm = (gender === 'male') ? maleNorms : femaleNorms;
      const pointSet = (gender === 'male') ? malePoints : femalePoints;

      if (u < norm[0]) {
        gripPoints = exponentialRenormalize(0,norm[0],80, pointSet[0], u);
        category = 'Extremely Weak';
      }
      else if (u < norm[1]) {
        gripPoints = exponentialRenormalize(norm[0],norm[1],pointSet[0], pointSet[1], u);
        category = 'Weak';
      }
      else if (u < norm[2]) {
        gripPoints = exponentialRenormalize(norm[1],norm[2],pointSet[1], pointSet[2], u);
        category = 'Slightly Weak';
      }
      else if (u >= norm[2]) {
        gripPoints = 300;
        category = 'Strong';
      }
    }

    if (l) {
      const maleNorms = [90,130,170];
      const malePoints = [100, 212, 252, 300]
      const femaleNorms = [60,80,100];
      const femalePoints = [268, 292, 292, 300]
      const norm = (gender === 'male') ? maleNorms : femaleNorms;
      const pointSet = (gender === 'male') ? malePoints : femalePoints;

      if (l < norm[0]) {
        legPoints = exponentialRenormalize(0,norm[0],80, pointSet[0], l);
        category = 'Extremely Weak';
      }
      else if (l < norm[1]) {
        legPoints = exponentialRenormalize(norm[0],norm[1],pointSet[0], pointSet[1], l);
        category = 'Weak';
      }
      else if (l < norm[2]) {
        legPoints = exponentialRenormalize(norm[1],norm[2],pointSet[1], pointSet[2], l);
        category = 'Slightly Weak';
      }
      else if (l >= norm[2]) {
        legPoints = 300;
        category = 'Strong';
      }      
    }
    if (legExtension) {}
    if (squat) {}
    if (bench) {}

    // points = parseFloat(((gripPoints + legPoints) / 2).toFixed(2));
    let strengthGraphValue;
    if (l) {
      points = legPoints;
      strengthGraphValue = l;
    }
    else if (u) {
      points = gripPoints;
      strengthGraphValue = u;
    }
    if (l && u) {
      points = (legPoints * 10 + gripPoints)/11;
      strengthGraphValue = parseFloat(((l + u)/2).toFixed(1));
    }
    // points = (legPoints * 10 + gripPoints)/11;

    if (isGraphPoint) {
      return points;
    }
    
    let graphData = [];
    for (let i=0; i<6; i++) {
      graphData.push({
        points: calcStrengthPoints(i*12.5, i*25, true),
        value: i*25,
        current: false
      });
    }
    graphData.push({
      points: points,
      value: strengthGraphValue,
      current: true
    });



    setStrengthGraphData(graphData.sort( (a,b) => {
      return (a.value - b.value);
    }));
    setStrengthPoints(points);
    setStrengthCategory(category);
  }


  const calcSmokingPoints = () => {
    let graphData = [
      {
        points: 180,
        value: 'never',
        current: false
      },
      {
        points: 176,
        value: '<2ppY',
        current: false
      },
      {
        points: 170,
        value: '<1ppY',
        current: false
      },
      {
        points: 168,
        value: '1ppM',
        current: false
      },
      {
        points: 159,
        value: '¼ppd',
        current: false
      },
      {
        points: 147,
        value: '½ppd',
        current: false
      },
      {
        points: 129,
        value: '1ppd',
        current: false
      },
      {
        points: 100,
        value: '2ppd',
        current: false
      }
    ];
    let points = 180;
    switch (smokingFreq) {
      case 'never': points = 180; setSmokingCategory('Never'); break;
      case '1yr': points = 176; setSmokingCategory('Less than 2 packs per year (or equivalent)'); break;
      case '6mo': points = 170; setSmokingCategory('About 2 packs every 6 months'); break;
      case '1mo': points = 168; setSmokingCategory('About 2 packs per month'); break;
      case '1wk': points = 159; setSmokingCategory('About 2 packs per week (¼ pack per day)'); break;
      case '4d': points = 147; setSmokingCategory('About ½ pack per day'); break;
      case '2d': points = 129; setSmokingCategory('About 1 pack per day'); break;
      case '1d': points = 100; setSmokingCategory('About 2 packs per day (or more)'); break;
    }

    for (let i=0; i<graphData.length; i++) {
      if (graphData[i].points === points) {
        graphData[i].current = true;
      }
    }
    setSmokingPoints(points);
    setSmokingGraphData(graphData);
  }

  const calcSocialPoints = () => {
    const checkedCount = Object.values(socialScore).filter(Boolean).length;
    const socialOptions = ['Extremely Isolated', 'Isolated', 'Minimally Connected', 'Somewhat Connected', 'Notably Connected', 'Widely Connected'];
    let graphData = [];
    for (let i=0; i < socialOptions.length; i++) {
      let points = exponentialRenormalize(0,5,100,150,i);
      points = parseFloat(points.toFixed(1));

      graphData.push({
        points: points,
        value: socialOptions[i],
        current: (i === checkedCount) ? true : false
      });

      if (i === checkedCount) {
        setSocialCategory(socialOptions[checkedCount]);      
        setSocialPoints(points);
      }
    }
    setSocialGraphData(graphData);
  }

  const calcBpPoints = (m) => {
    const hypotensiveMAP = 68;
    const normotensiveMAP = 76;
    const preHtnMAP = 99;
    const htnMAP = 106;
    const MAPlevels = [68,76,99,106];

    let MAP = 0;
    let graphData = [];
    if (!(m)) {
      MAP = sbp * 0.3333333 + dbp * 0.6666666;
    }
    else {
      MAP = m;
    }

    let points = 100;
    if (MAP <= hypotensiveMAP) {
      points = 70;
    }
    else if (MAP <= normotensiveMAP) {
      points = parseFloat(exponentialRenormalize(68,76,70,115,MAP).toFixed(2));
    }
    else if (MAP <= preHtnMAP ) {
      points = 115;
    }
    else if (MAP <= htnMAP) {
      points = 115 - parseFloat(exponentialRenormalize(99,106,1,15,MAP).toFixed(2));
    }
    else if (MAP > htnMAP) {
      points = 100 - parseFloat(exponentialRenormalize(106,126,1,10,MAP).toFixed(2));
    }
    switch (true) {
      case (MAP < hypotensiveMAP): setBpCategory('HYPOTENSIVE - URGENT, CONTACT PHYSICIAN'); break;
      case ((MAP >= hypotensiveMAP) && (MAP < htnMAP)): setBpCategory('Pre-hypertension'); break;
      case (MAP > htnMAP): setBpCategory('Hypertensive'); break;
      default: setBpCategory('Normal Blood Pressure');
    }
    if (m) return points;
    for (let i=0; i<MAPlevels.length; i++) {
      let p = calcBpPoints(MAPlevels[i]);
      graphData.push({
        points: p,
        value: MAPlevels[i],
        current: false
      });
    }
    let highMap = MAPlevels[MAPlevels.length-1] + 20
    let highpoints = calcBpPoints(highMap);
    graphData.push({
      points: highpoints,
      value: highMap,
      current: false
    });
    //add current point/value to graphData
    MAP = parseFloat(MAP.toFixed(1));
    graphData.push({
      points: points,
      value: MAP,
      current: true
    });


    setBpGraphData(graphData.sort( (a,b) => {
      return (a.value - b.value);
    }));
    setBpPoints(points);
    
  }


  const handleGenderChange = (e) => {
    setGender(e.target.value);
  }

  const handleKneeDyno = (e) => {
    let d = +e.target.value;
    setKneeDyno(d);
    setLegExtension((d/((legMomentArm/100) * 9.81))*2.2); //divde by moment arm in m (from cm), convert to kg (div 9.81), convert to lb (*2.2)
  }

  const handleLegExtension = (e) => {
    let wt = +e.target.value;
    setLegExtension(wt);
    setKneeDyno((wt / 2.2)*9.81*(legMomentArm/100)); //wt, convert to kg (div2.2), convert to Newtons (*9.81), multiply by moment arm in m (from cm)
  }
  const handleMomentArm = (e) => {
    let length = +e.target.value;
    setLegMomentArm(length);
    setKneeDyno((legExtension / 2.2)*9.81*(length/100)); //wt, convert to kg (div2.2), convert to Newtons (*9.81), multiply by moment arm in m (from cm)
  }

  const handleSmokingChange = (e) => {
    setSmokingFreq(e.target.value);
  }
  
  const handleSocialChange = (e) => {
    const { name, checked } = e.target;
    setSocialScore(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const dashBoardData = {
    'vo2': vo2,
    'vo2Percent': (vo2Points) / (vo2Maxpoints),
    'strengthPercent': (strengthPoints) / (strengthMaxPoints),
    'smokingPercent': (smokingPoints-100) / (smokingMaxPoints-100),
    'socialPercent': (socialPoints-100) / (socialMaxPoints-100),
    'bpPercent': (bpPoints-100) / (bpMaxPoints-100),
    'totalLongevityPercentage': totalLongevityPercentage,
    'vo2Category': vo2Category,
    'strengthCategory': strengthCategory,
    'smokingCategory': smokingCategory,
    'socialCategory': socialCategory,
    'bpCategory': bpCategory
  }

  return (
    <div className="section section--longevity">
      <Header>LONGEVITY APP</Header>
      <div className="section__content container">
        <MenuButton />
        <div className="content-wrapper">
          <div className='block--title'>
            <h1>Pseudo-Scientific<br />Longevity Estimator</h1>
            <p>This is Bullshit</p>
          </div>
          <div className="content">
            <div className="block--image">
              {totalLongevityPercentage !== null && (
                <div className='longevitydashboard  '>
                  <div className='longevitygraphs'>
                    <LongevityDashboard inputData={dashBoardData}/>
                    {/* <BasicLongevityComponent vo2Start={10}/> */}
                  </div>
                </div>
              )}                 
            </div>
            <div className="block--text">
                <div className="age item">
                  <h2>Age</h2>
                  <label>
                    Age:&nbsp; &nbsp;
                    <input 
                      type="number" 
                      value={age} 
                      onChange={(e) => {setAge(e.target.value)}} 
                      required 
                    />
                  </label>
                </div>
                <div className="sex item">
                  <h2>Sex</h2>
                  <fieldset>
                    <legend>Biological Sex:</legend>
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={gender === 'male'}
                        onChange={handleGenderChange}
                      />
                      MALE
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={gender === 'female'}
                        onChange={handleGenderChange}
                      />
                      FEMALE
                    </label>
                  </fieldset>
                </div>

                <div className="vo2 item">
                  <h1>VO2 Max</h1>
                  <p>The single most powerful predictor of mortality/longevity</p>
                  <label>
                    VO2:&nbsp; &nbsp;
                    <input 
                      type="number"
                      step="0.1" 
                      value={vo2} 
                      onChange={(e) => {setVo2(parseFloat(e.target.value));}} 
                      required 
                    /> ml/kg/min
                    <RangeSlider
                      min='0'
                      max='97'
                      step="1"
                      value={vo2}
                      onChange={(e) => { setVo2(+e.target.value) }}
                      customClasses='vo2 range input'
                    />                     
                  </label>
                  <h3>{vo2Category}</h3>
                  <ComponentGraph inputData={vo2GraphData} name="VO2 Max" />
                </div>

                <div className="strength item">
                  <h1>Strength</h1>
                  <p>The second most powerful predictor of mortality/longevity</p>
                  <fieldset>
                    <legend>Strength Measurement Options</legend>
                    <label>
                      Grip Dynamometer Max:&nbsp; &nbsp;
                      <input 
                        type="number"
                        step="0.1" 
                        value={gripDyno} 
                        onChange={(e) => {setGripDyno(parseFloat(e.target.value));}} 
                        required 
                      />kg
                      <RangeSlider
                        min='0'
                        max='100'
                        step="0.1"
                        value={gripDyno}
                        onChange={(e) => { setGripDyno(+e.target.value) }}
                        customClasses='strength grip dynamometer range input'
                      />                                
                    </label> <br />
                    <label>
                      Knee Dynamometer Max:&nbsp; &nbsp;
                      <input 
                        // disabled={true}
                        type="number"
                        step="1" 
                        value={kneeDyno} 
                        onChange={handleKneeDyno} 
                        required 
                      />Nm
                      <RangeSlider
                        // disabled={true}
                        min='0'
                        max='200'
                        step="1"
                        value={kneeDyno}
                        onChange={handleKneeDyno}
                        customClasses='strength knee dynamometer range input'
                      />                                
                    </label> <br />
                    <label>
                      Leg Extension Max:&nbsp; &nbsp;
                      <input 
                        className='leg extension number input'
                        type="number"
                        step="0.1" 
                        value={legExtension} 
                        onChange={handleLegExtension} 
                        required 
                      />lbs
                      <RangeSlider
                        // disabled={true}
                        min='0'
                        max='135'
                        step="0.5"
                        value={legExtension}
                        onChange={handleLegExtension}
                        customClasses='strength leg extension range input'
                      />            
                    </label> <br />
                    <label>
                      Leg Extension Moment Arm:&nbsp; &nbsp;
                      <input 
                        className='leg moment arm'
                        type="number"
                        step="0.1" 
                        value={legMomentArm} 
                        onChange={handleMomentArm} 
                        required 
                      />cm
                      <RangeSlider
                        // disabled={true}
                        min='30'
                        max='38'
                        step="0.1"
                        value={legMomentArm} 
                        onChange={handleMomentArm} 
                        customClasses='strength moment arm range input'
                      />            
                    </label> <br />
                    <label>
                      Squat:&nbsp; &nbsp;
                      <input 
                        disabled={true}
                        type="number"
                        step="0.1" 
                        value={squat} 
                        onChange={(e) => {setSquat(parseFloat(e.target.value));}} 
                        required 
                      />
                      <RangeSlider
                        disabled={true}
                        min='0'
                        max='97'
                        value={squat}
                        onChange={(e) => { setSquat(+e.target.value) }}
                        customClasses='strength squat range input'
                      />                           
                    </label> <br />
                    {/* <label>
                      Bench Press:&nbsp; &nbsp;
                      <input 
                        type="number"
                        step="0.1" 
                        value={bench} 
                        onChange={(e) => {setBench(parseFloat(e.target.value));}} 
                        required 
                      />
                      <RangeSlider
                        min='0'
                        max='97'
                        value={vo2}
                        onChange={(e) => { setVo2(+e.target.value) }}
                        customClasses='strength leg extension range input'
                      />                           
                    </label> <br /> */}
                  </fieldset>
                  <h3>{strengthCategory}</h3>
                  <ComponentGraph inputData={strengthGraphData} name="Strength" />
                </div>



                <div className="smoking item">
                  <h1>Smoking</h1>
                  <h3>There is only good data for tobacco smoking. There is likely to be some risk for both smokeless tobacco as well as non-tobacco smoking, but the quantification is unclear.</h3>
                  <p>1 cigar = roughly 1 cigarette. Higher amount of total tobacco, but usually less/minimal lung inhalation.</p>
                  <p>If deeply inhaled, 1 cigar = roughly ¼ pack</p>
                  <p>1 bowl of hookah = ½ pack of cigarettes</p>
                  <p>How long does it take you to smoke the equivalent of 2 packs of cigarettes?</p>
                  <fieldset>
                    <legend>Smoking Frequency:</legend>
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="never"
                        checked={smokingFreq === 'never'}
                        onChange={handleSmokingChange}
                      />
                      Never, or less than 2 packs per year
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="1yr"
                        checked={smokingFreq === '1yr'}
                        onChange={handleSmokingChange}
                      />
                      About 2 packs per year, or equivalent
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="6mo"
                        checked={smokingFreq === '6mo'}
                        onChange={handleSmokingChange}
                      />
                      About every 6 months
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="1mo"
                        checked={smokingFreq === '1mo'}
                        onChange={handleSmokingChange}
                      />
                      About every month
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="1wk"
                        checked={smokingFreq === '1wk'}
                        onChange={handleSmokingChange}
                      />
                      About every week (~ ¼ pack, 5 cigarettes / day)
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="4d"
                        checked={smokingFreq === '4d'}
                        onChange={handleSmokingChange}
                      />
                      About every 4 days (~ ½ pack per day)
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="2d"
                        checked={smokingFreq === '2d'}
                        onChange={handleSmokingChange}
                      />
                      About 1 pack per day
                    </label><br />
                    <label>
                      <input
                        type="radio"
                        name="smoking"
                        value="1d"
                        checked={smokingFreq === '1d'}
                        onChange={handleSmokingChange}
                      />
                      2 packs or more every day
                    </label><br />
                  </fieldset>
                  {/* <h3>{smokingCategory}</h3> */}
                  <ComponentGraph inputData={smokingGraphData} name="Smoking" />
                </div>

                <div className="social item">
                  <h2>Social Connectedness</h2>
                  <h3>Interestingly, it seems that only the amount of connections (isolation) counts for longevity, not the subjective feeling of loneliness.</h3>
                  <p>The subjective experience, of course, is deeply linked to your quality, and meaningfulness of life.</p>
                  <p>As an additional consideration, these numbers are likely to be underestimates, since the studies deliberately disregarded deaths from suicide and accident. If we include those numbers (and we probably should), the total "point" value here would be significantly higher.</p>
                  <fieldset>
                    <legend>Social Connectedness</legend>
                    <label>
                      <input
                        type="checkbox"
                        name="married"
                        checked={socialScore.married}
                        onChange={handleSocialChange}
                      />
                      Are you married or cohabitating with an intimate partner?
                    </label><br />
                    <label>
                      <input
                        type="checkbox"
                        name="organizations"
                        checked={socialScore.organizations}
                        onChange={handleSocialChange}
                      />
                      Do you participate in any organizations or groups, including social clubs or residents groups, religious groups, or committees?
                    </label><br />
                    <label className='note'>
                      <span className='interaction-span'>Do you interact (by any direct, 1-on-1 medium, including: <br /> e-mail, telephone, or face-to-face) more frequently than once per month with: </span> <br />
                      <input
                        type="checkbox"
                        name="nuclearFam"
                        checked={socialScore.nuclearFam}
                        onChange={handleSocialChange}
                      />
                      Your children or your parents?
                    </label><br />
                    <label>
                      <input
                        type="checkbox"
                        name="extendedFam"
                        checked={socialScore.extendedFam}
                        onChange={handleSocialChange}
                      />
                      Other family members?
                    </label><br />
                    <label>
                      <input
                        type="checkbox"
                        name="friends"
                        checked={socialScore.friends}
                        onChange={handleSocialChange}
                      />
                      Friends?
                    </label><br />
                  </fieldset>
                  <h3>{socialCategory}</h3>
                  <ComponentGraph inputData={socialGraphData} name="Social Connectedness" />
                </div>
                

                <div className="bp item">
                  <h2>Blood Pressure</h2>
                  <p>MAP (Mean Arterial Pressure) is a way of combining Systolic Blood Pressure (the top number) and Diastolic Blood Pressure, into a single number, by adding ⅓ of the SBP with ⅔ of the DBP, since the heart spends roughly 1/3 of the time in systole.<br /><br /></p>
                  <label>
                    SBP (the top number):&nbsp;&rarr; &nbsp;
                    <input 
                      type="number"
                      value={sbp} 
                      onChange={(e) => {setSbp(parseInt(e.target.value, 10));}} 
                      required 
                      className='sbp number input'
                    />
                    <RangeSlider
                      min='60'
                      max='205'
                      value={sbp}
                      onChange={(e) => { setSbp(+e.target.value) }}
                      customClasses='bp sbp range input'
                    />                          
                  </label><span className='bp-slash'>&nbsp; / &nbsp;</span>
                  <label>
                    <input 
                      type="number"
                      value={dbp} 
                      onChange={(e) => {setDbp(parseInt(e.target.value, 10));}} 
                      required 
                    />
                    <RangeSlider
                      min='40'
                      max='110'
                      value={dbp}
                      onChange={(e) => { setDbp(+e.target.value) }}
                      customClasses='bp dbp range input'
                    />                        
                    &larr;&nbsp;DBP (the bottom number)
                  </label>
                  <h3>{bpCategory}</h3>
                  <ComponentGraph inputData={bpGraphData} name="Blood Pressure (MAP)" />
                </div>                

                <div className="sleep item">
                  <h2>Why Not Sleep?</h2>
                  <p className='sleep citation para'>Many people think sleep is a longevity factor. I'm not convinced (yet).</p>
                  <p className='sleep citation para'>My view on sleep is somewhat complex, but in the current climate, I end up being a bit of a renegade. Everyone talks about the importance of sleep, but there's a ton of misinformation out there.</p>
                  <p className='sleep citation para'>A lot of popular information is hype and has created a culture in which many people just *can't believe* that sleep isn't a mortality factor. But, consider <a href='https://guzey.com/books/why-we-sleep/'>this.</a></p>
                  <p className='sleep citation para'>The main, commonly cited studies are simple surveys asking people how long they slept on average. We're still waiting on a new generation of studies using sleep tracking data. But even in these studies, most of them show a u-shaped curve (with gender differences - women seem more resistant to the effects of short sleep than men). <a href='https://doi.org/10.1001/jamanetworkopen.2021.22837'>citation</a>.</p>
                  <p className='sleep citation para'>You can also check out this meta-analysis showing how small the likely effect size is, especially for short sleep. <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2864873/'>citation</a>.</p>
                  <p className='sleep citation para'>As you can see, while those studies did show a correlation (NOT causation) between shorter sleep and mortality, it wasn't huge - the real huge effect was between *long* sleep and mortality. <br />
                    And, these studies aren't randomized or controlled, like so many longevity topics, so there's the constant issue of confounding. Here's a study showing that once you adjust for physical activity, the supposed mortality/longevity issues around of sleep disappear.
                    <a href='https://pubmed.ncbi.nlm.nih.gov/33713846/'>citation</a>.
                  </p>
                  <p className='sleep citation para'>Now, where the data for sleep seems more interesting is its effect on muscle building and the importance of sleep for developing muscle and strength. So even though sleep contributes, the real *metric* to track isn't sleep itself, but muscle - if you aren't sleeping well, but you're still able to develop muscle, then in my opinion, sleep itself isn't a mortality factor.</p>
                  <p className='sleep citation para'>There is one more really important commentary to make here, by way of analogy. I'm a huge proponent of social connectedness as a mortality factor. However, what's interesting is that the subjective effect of that connectedness doesn't seem to be a longevity factor - meaning, even if the person still *feels* lonely, but participates in a variety of social interactions, they are getting the longevity benefit <br />
                  -But NOT the quality of life benefit.</p>
                  <p className='sleep citation para'>Quality relationships, like quality sleep, MATTER a ton, even if they don't have direct longevity benefits. We don't need to justify the importance of sleep, or relationships, by relying on mortality. It's enough that they improve the quality of our lives, and are worth optimizing for that reason.
                  </p>
                  {/* <h3>Here's my reasoning: <a target="_blank" href="https://youtube.com/@riverrockmedical">Video</a></h3> */}
                </div>                
                <div className="citations item">
                  <h2>Citations:</h2>
                  <h3><a target="_blank" href="https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2707428">VO2 Max</a></h3>
                  <h3><a target="_blank" href="https://www.mayoclinicproceedings.org/article/S0025-6196(15)00642-4/pdf">VO2 Max Norms</a></h3>
                  <h3><a target="_blank" href="https://academic.oup.com/biomedgerontology/article/61/1/72/549632">Strength</a></h3>
                  <h3><a target="_blank" href="https://www.bmj.com/content/337/bmj.a439">Strength Independent of VO2 Max</a></h3>
                  <h3><a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/27918784/">Smoking</a></h3>
                  <p><a target="_blank" href="https://cancercontrol.cancer.gov/sites/default/files/2020-08/m09_1.pdf">Smoking types and equivalencies</a></p>
                  <h3><a target="_blank" href="https://pnas.org/doi/full/10.1073/pnas.1219686110">Social Connections</a></h3>
                  <p className='citation para commentary'>This is a complex issue. Frankly, it is nearly impossible to ethically obtain definitive *causal* information on the health effects of social connections. We cannot randomly assign groups of people to a life of isolation. However, we are working on randomly assigning isolated people to interventions that increase social connectedness, and those seem to have promising improvements in mortality. For that reason, I elected to include it here, though the science is still far from definitive.</p>
                  <h3><a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/19588327/">Blood Pressure - the study I used</a></h3>
                  <p className='citation para commentary'>For what it's worth, I am being a bit generous in my formulation of blood pressure as a mortality risk factor based on the study above (2009 meta-analysis). In reality the evidence is somewhat worse, since several other studies and meta-analyses (cited below) found **NO benefit** in overall mortality from treatment of blood pressure.</p>
                  <p className='citation para commentary'>It is worth noting that the hazard ratio effect sizes in observational studies are MUCH bigger (HR ~1.5-2.5) than in treatment studies. This is a classic finding of confounding, where if you just compare the mortality of people with high BP vs normal BP (observational), there is a huge difference due to multiple factors. Whereas when you compare people with high BP who get treated vs placebo, there is small or zero effect on mortality, showing that the BP itself was a minimal contributor to the mortality, vs a *marker* of other health factors, like VO2 Max.</p>
                  <p className='citation para commentary'>However, since several studies do show some mortality benefit (NNT ~125) from treating BP itself (though, interestingly, beta blockers do not seem to have this benefit, so it's still not clear if it's a medication effect or a blood pressure effect), I decided to include Blood Presure as a factor for now.</p>
                  <h4>Other BP studies:</h4>
                  <h3><a target="_blank" href="https://thennt.com/nnt/anti-hypertensives-to-prevent-death-heart-attacks-and-strokes/">Blood Pressure NNT</a></h3>
                  <h3><a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/22895954/">Blood Pressure - no benefit</a></h3>
                  <h3><a target="_blank" href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(21)00590-0/fulltext">Blood Pressure - no benefit</a></h3>
                </div>                
            </div>
          </div>
        </div>
        <span className="section__tip">
          LONGEVITY ESTIMATOR
        </span>
      </div>
    </div>
  );
}

export default LongevityApp;
