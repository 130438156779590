import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import { HandleScroll } from '../../actions/app';
/**
 * 
 */
const Services = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);
  const navigate = useNavigate();

  const scroll = (e) => {
    let result = HandleScroll(e);
    if (result === 'prev' && prevPath)
      navigate(prevPath);
    if (result === 'next' && nextPath)
      navigate(nextPath);
  }

  return (
    <div className="section section--services" onWheel={scroll}>
      <Header>SERVICES</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (  
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              <h5>Everything  here is included with membership</h5>
              <p className="text-content">
                We get so excited about our advances in healthcare that we sometimes forget to mention that we're no slouches in the basics.
              </p>
              <h6>The absolute best medical care available</h6> 
              <p className='text-content'>Dr. Hakeem is Board Certified in Internal Medicine, and has worked with the sickest of the sick in hospitals and ICUs across the nation for more than a decade. <br /> <br />
                In addition to world-class diagnostics and disease care, we also offer an unmatched set of advanced methods, in service of our MeaningSpan protocols.
              </p>
              <img className="purpose-cover" src="/images/purpose-cover.svg" alt="purpose icon" />
              {/* <iframe src="https://www.youtube.com/embed/rO1dapylKgY?si=fP7U4k56Qpe8AHm8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
              {/* ADD YOUTUBE EMBED OF MeaningSpan VIDEO */}
            </div>
            <div className="block--text">
              <h5>Services Available</h5>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">VO2Max</a></h6>
              <p className='text-content'>The single most important predictor of lifespan and healthspan</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Strength</a></h6>
              <p className='text-content'>The second-most important predictor of lifespan and healthspan</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">1-hr Office Visits & No Waiting</a></h6>
              <p className='text-content'>Phone and Video also available, 30-60min slots</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Setup RiverRock Accounts</a></h6>
              <p className='text-content'>Threaded secure medical chat, online scheduling, task-tracking, full records access and control</p>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Advanced Online Infrastructure</a></h6>
              <p className='text-content'>Threaded medical chat, online scheduling, full records access and control</p> */}
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Meaning and Purpose</a></h6>
              <p className='text-content'>Multiple hours of goal-setting conversation; motivators; evaluation of habits; root cause analysis to determine obstacles to you meeting goals</p> */}
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Baseline Echo, and EKG as needed</a></h6>
              <p className='text-content'>These have no value in the present, but we think ahead - having a baseline can have great value in the future.</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Most Bloodwork drawn in office</a></h6>
              <p className='text-content'>If medically indicated, we may perform additional bloodwork:</p>
              <p>
                <ul style={{marginTop: -1+'rem'}}>
                  <li>Diagnostic or Disease-specific bloodwork</li>
                  <li>NMR cholesterol</li>
                  <li>Saliva and/or Blood Hormone Baseline</li>
                  <li>14-day Continuous Glucose Monitoring Prescription</li>
                  <li>GALLERI by GRAIL - multicancer detection</li>
                  <li>CardioDiagnostics AI-driven Epigenetic + Genetic, and/or Precision CHD Cardiac Risk Testing</li>
                </ul>
              </p> */}
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Detailed Metabolic Assessment</a></h6>
              <p className='text-content'>Including RMR, Carb Burn Ratio, and FATMAX</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Health article review & topic research</a></h6>
              <p className='text-content'>Curious about a new health article you found? Just ask your doctor</p>
              <h6><a href="https://youtube.com/@riverrockmedical">EEG Neurofeedback with Myndlift</a></h6>
              <p className='text-content'>Brainwave biofeedback, for anxiety, focus, or cognitive enhancement</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Vagal training</a></h6>
              <p className='text-content'>e.g. Resonance Frequency Breathing</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Rapid Online Specialist Access</a></h6>
              <p className='text-content'>Through RubiconMD</p>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Home Telehealth Kit</a></h6>
              <p className='text-content'>For accurate BP, heart rate, pulse ox, etc. at home</p> */}
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Pulmonary Function Testing</a></h6>
              <p className='text-content'>In-office Spirometry</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Cognitive Function Testing</a></h6>
              <p className='text-content'>Through Creyos</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Hypnosis</a></h6>
              <p className='text-content'></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Osteopathic Manipulation</a></h6>
              <p className='text-content'></p>
              {/* <p className='text-content'>Including craniosacral as well as "crack/pop" techniques</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Trigger Point Injections</a></h6>
              <p className='text-content'>AKA 'wet needling'</p>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Personality Inventory Testing</a></h6>
              <p className='text-content'>Primarily Big-5 methodology, also familiar with Enneagram</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Platelet-Rich Plasma</a></h6>
              <p className='text-content'>PRP for alopecia, skin, or joint/tendon injuries</p>
              <h6><a href="https://youtube.com/@riverrockmedical">IV therapies and fluids as needed</a></h6>
              <p className='text-content'></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Bayesian Diagnostics and Risk Assessment</a></h6>
              <p className='text-content'>Dr.Z made the app - <a href="https://rxbayes.com">Rx-Bayes</a></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Patient Task Tracking</a></h6>
              <p className='text-content'>We'll help you keep track of what screening, tests, and followups you need</p>
              <h6><a href="https://youtube.com/@riverrockmedical">4 Months of Intensive Coaching</a></h6>
              <p className='text-content'>For when you're ready to make lifestyle changes, but need some encouragement</p>
              <h2>By the time you read it, this list will probably be incomplete.</h2>
            </div>
          </div>
        </div>
        <p className="section__quote">
          “It is not daily increase, but daily decrease.<br />
          Hack away at the unessential”<br />
          -Bruce Lee<br />
        </p>
        <span className="section__tip">
          SERVICES
        </span>
      </div>
    </div>
  );
};

export default Services;
