import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import { HandleScroll } from '../../actions/app';

const Purpose = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);
  const navigate = useNavigate();

  const scroll = (e) => {
    let result = HandleScroll(e);
    if (result === 'prev' && prevPath)
      navigate(prevPath);
    if (result === 'next' && nextPath)
      navigate(nextPath);
  }
  const isMobile = (window.innerWidth < 768);
  let videoSrc = (isMobile) ? 
    'https://www.youtube.com/embed/rO1dapylKgY?si=fP7U4k56Qpe8AHm8&autoplay=0&mute=0&loop=0&showinfo=0&controls=1&color=white&rel=0&playsinline=0&enablejsapi=1&playlist=rO1dapylKgY&origin=riverrockmedical.com' :
    'https://www.youtube.com/embed/rO1dapylKgY?si=fP7U4k56Qpe8AHm8&autoplay=0&mute=0&loop=0&showinfo=0&controls=0&color=white&rel=0&playsinline=0&enablejsapi=1&playlist=rO1dapylKgY&origin=riverrockmedical.com';

  return (
    <div className="section section--purpose" onWheel={scroll}>
      <Header>OUR VISION</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              {/* <img className="purpose-cover" src="/images/purpose-cover.svg" alt="purpose icon" /> */}
              <h5>The RiverRock Vision</h5>
              <p className="text-content">
                It's hard to explain how differently we see healthcare. This video explains our Vision &darr;:
              </p>
              <iframe src={videoSrc} title="RiverRock MeaningSpan" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen="allowfullscreen"></iframe>

              {/* <h5>Clear Communication</h5>
              <p className="text-content">
                Despite their deep knowledge, our Physicians have mastered a "forgotten art" - talking and planning with you in plain English, without medical-ese.<br /><br /> You never leave confused about your care plan<br /><br />
              </p> */}
            </div>
            <div className="block--text">
            {/* </div>
            <div className="block--text"> */}
              <h5>Dream-driven</h5>
              <h2>Our purpose is maximizing your potential.</h2>
              <p className="text-content">
                <Link to="/what-is-health">Health</Link> is the capacity to do something worth doing. <br /> <br />
                {/* This is the root of everything we do. RiverRock is its own first patient; we are constantly innovating, maximizing our own potential.<br /> <br /> */}
                {/* We’ve cut the deadweight of mainstream medicine, the outdated ideas and unnecessary bureaucracy.<br /> <br /> */}
                We’re not just optimizing lifespan (though we do), not just healthspan (though we do that too). We're optimizing <span className='italic'>MeaningSpan</span>.<br /> <br />
                Your sources of Meaning and Fulfillment, your Dreams, are the driving force behind the care we provide.<br />
                {/* Checkout our <Link to="/analysis">Case Studies.</Link><br /><br /> */}
              </p>
              {/* <p className='text-content'>Everything else is unessential.</p> */}
              <h5>Data-assisted</h5>
              <p className="text-content">
                Your dreams define our targets, but our methods are rigorously analytic.<br /><br /> 
                In addition to keeping up to date on the latest <Link to="/studies">studies</Link>, our physicians are trained in advanced methods, such as long-tail statistics, metabolic mapping, motivational interviewing, and avoiding cognitive errors.<br /><br />
                Our care is grounded in established science (<a href="https://docs.google.com/document/d/1YNEjr8PfzfMcZhx0CmxV7AvXH-evZxLdob696GPpWWk/edit?usp=sharing">with caveats</a>), but goes beyond what's established today, into the methods of tomorrow. <br /><br />
                It's hard to improve what you don't measure. And we have the pre-eminent scientific understanding of <span className='italic'>what's worth measuring.</span> <br /><br />
                {/* Because it's hard to improve what you can't measure, we help you with health data collection, and we are prepared to discuss whatever patterns we discover.  */}
                {/* <Link to="/data-assisted">Learn more</Link> <br /><br /> */}
              </p>              
              <h2>Take a look at our data-gathering standards. <br />It's unlike anything you've ever seen. &nbsp;&darr;&nbsp; </h2>
              {/* <h5>No distractions.</h5> */}
              {/* <h5>No excuses.</h5> */}
              {/* <h5>No shortcuts.</h5> */}
              {/* <h5>Just pure movement forward.</h5> */}
              {/* <p className="text-content"><Link to="/data-assisted.html">Is this just hokey holistic nonsense?
              *** NEXT PAGE SHOULD BE NEW VERSION OF "DATA-ASSISTED", YOUTUBE EMBED OF MONTAGE, DESCRIBING ALL DATA COLLECTED AND SERVICES OFFERED
              </Link></p> */}
              {/* <h2>See what that looks like in practice &nbsp;&rarr;&nbsp; </h2> */}
            </div>
          </div>
        </div>
        <p className="section__quote">
          “Information is not knowledge. <br />
          The only source of knowledge is experience. <br />
          You need experience to gain wisdom.”<br />
          -Albert Einstein<br />
        </p>
        <span className="section__tip">
          OUR VISION
        </span>
      </div>
    </div>
  );
};

export default Purpose;
