import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';

const Studies = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);

  useEffect(() => {
    const startImageAnimation = () => {
      const manTransparent = document.getElementById('man-transparent');
      if (manTransparent) {
        manTransparent.addEventListener('load', (e) => {
          e.target.classList.add('loaded');
        });
      }
    };
    startImageAnimation();
  }, []);

  return (
    <div className="section section--studies">
      <Header>STUDIES AND REFERENCES</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--text">
              <h1>Stuff I Wish Patients Knew</h1>
              <h2>Studies, Links, Lectures, and Book Prescriptions</h2>
              <p>This page will continue to grow over time.</p>
              <img className="z-swoosh" src="./images/swoosh.png" alt="swoosh" />
              <h2>Genetics</h2>
              <p>
                <a className="book" href="https://www.amazon.com/Chaos-making-science-James-GLEICK/dp/0747404135/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1601169377&sr=8-2">Introduction to Chaos Theory</a><br />
                <a className="article" href="https://xkcd.com/1605/">XKCD: Biology is Impossible</a><br />
                <a className="article" href="https://arstechnica.com/science/2018/11/genetics-play-less-of-a-role-in-lifespan-than-we-thought/">How much of longevity is related to genetics?</a><br />
                <a className="article" href="https://gizmodo.com/consumer-dna-testing-may-be-the-biggest-health-scam-of-1839358522">23andme, other consumer dna testing scams</a><br />
                <a className="article" href="https://www.statnews.com/2018/02/20/diet-genetic-matching/">DNA diet recommendations don't work</a><br />
                <a className="article" href="https://gizmodo.com/dont-take-the-dna-test-youll-probably-get-for-christmas-1831068871">Ancestry DNA analysis problems</a><br />
                <a className="article" href="https://gizmodo.com/the-next-pseudoscience-health-craze-is-all-about-geneti-1792194708">Consumer Genetics Tests are Pseudoscience</a> <br />
                <a className="article" href="https://www.technologyreview.com/2017/10/18/105311/how-to-spend-1900-on-gene-tests-without-learning-a-thing/">How to spend $1900 on gene tests without learning a thing</a> <br />
                <a className="article" href="https://futurism.com/neoscope/home-dna-genomic-data">Good luck keeping your genetic data private</a> <br />
                <a className="article" href="https://www.livescience.com/63997-dna-ancestry-test-results-explained.html">I Took 9 Different Commercial DNA Tests and Got 6 Different Results</a> <br />
                <a className="article" href="https://www.scientificamerican.com/article/how-accurate-are-online-dna-tests/">How accurate are online DNA tests?</a> <br />
                <a className="article" href="https://www.vox.com/science-and-health/2019/1/28/18194560/ancestry-dna-23-me-myheritage-science-explainer">The limits of ancestry DNA tests, explained</a> <br />
                <a className="article" href="https://www.cbc.ca/news/technology/dna-ancestry-kits-twins-marketplace-1.4980976">Twins get different genetic results from consumer companies</a> <br />
              </p>
              <h2>Lab tests and X-Rays</h2>
              <p>
                <a href="https://youtu.be/txWNQGNkeqY">Labs, Damn Lies, and Statistics</a> <br />
                <a className="app" href="https://rxbayes.com">COVID-19 Test Accuracy</a> <br />
                <a className="app" href="https://seeing-theory.brown.edu/bayesian-inference/index.html#section1">Bayesian Visualization of Medical Testing</a> <br />
              </p>
              <h2>Sleep</h2>
              <p>
                <a className="video" href="https://youtu.be/BfyCrbr7kok">Dr. Hakeem's Sleep Video, part 1/6</a> <br />
                <a className="video" href="https://youtu.be/Al6PMHTEw4o">Dr. Hakeem's Sleep Video, part 2/6</a> <br />
                <a className="video" href="https://youtu.be/Pg-c_CLG8do">Dr. Hakeem's Sleep Video, part 3/6</a> <br />
                <a className="video" href="https://youtu.be/Q1-8M42rKnU">Dr. Hakeem's Sleep Video, part 4/6</a> <br />
                <a className="video" href="https://youtu.be/_Tl7BXV9te8">Dr. Hakeem's Sleep Video, part 5/6</a> <br />
                <a className="video" href="https://youtu.be/PUqQBT9xdIU">Dr. Hakeem's Sleep Video, part 6/6</a> <br />
                <a className="book" href="https://read.amazon.com/kp/embed?asin=B06ZZ1YGJ5&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_y4-BFbKE4AA2V">Why We Sleep</a> <br />
                <a className="article" href="https://www.darksideofsleepingpills.com/">The Dark Side of Sleeping Pills</a> <br />
                <a className="journal" href="https://bmjopen.bmj.com/content/2/1/e000850">Sleeping Pills Increase the Risk of Death</a> <br />
                <a className="journal" href="https://pubmed.ncbi.nlm.nih.gov/30311830/">Effect of Light on Circadian Rhythm</a> <br />
                <a className="article" href="https://www.livescience.com/53874-blue-light-sleep.html">Blue Light Affects Sleep</a> <br />
                <a className="product" href="https://www.amazon.com/BluBlocker-Black-Viper-2703K/dp/B00DE0MYKC/ref=sr_1_14?dchild=1&keywords=blu+blockers&qid=1601171257&sr=8-14">Blue-Light Blocking Glasses</a> <br />

              </p>
              <h2>Diet</h2>
              <p>
                <a className="book" href="https://read.amazon.com/kp/embed?asin=B000VMFDR2&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_z.-BFb35GEV42">In Defense of Food</a> <br />
                <a className="book" href="https://read.amazon.com/kp/embed?asin=B000SEIDR0&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_C.-BFbQWJWZV2">The Omnivore's Dilemma</a> <br />
              </p>
              <h2>Relationships, Sex, Spirituality and Mental Health</h2>
              <p>
                <a className="book" href="https://www.amazon.com/Intimacy-M-D-Thomas-Patrick-Malone/dp/0130470856/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1601168670&sr=8-6">The Art of Intimacy</a> <br />
                <a className="book" href="https://www.amazon.com/dp/0553371320/ref=cm_sw_em_r_mt_dp_ix-BFbNZA6P7J">The Spirituality of Imperfection</a> <br />
                <a className="app" href="https://kinxlist.com">Kinky Activities Checklist</a> <br />
                <a className="article" href="https://www.nytimes.com/2015/01/09/style/no-37-big-wedding-or-small.html">36 Questions to Fall in Love (article, NYT)</a> <br />
                <a className="app" href="https://qlove-36.web.app/">36 Questions to Fall in Love (free web app)</a> <br />
                <a className="book" href="https://www.amazon.com/How-Change-Your-Mind-Psychedelics/dp/0241294223/ref=sr_1_2?dchild=1&keywords=pollan+change+mind&qid=1601171435&sr=8-2">How To Change Your Mind: The New Science of How-Change-Your-Mind-Psychedelics</a> <br />

              </p>
              <h2>Cholesterol and Statins (and aspirin)</h2>
              <p>Coming Soon</p>
              <h2>Financial Health</h2>
              <p>
                <a className="app" href="https://ynab.com">Financial Health - How to Budget (will change your life)</a> <br />
                <a className="app" href="https://healthequity.com/">Health Savings Account</a> <br />
              </p>
              <h2>Vitamins</h2>
              <p>
                <a className="journal" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1479533/">Vitamin E supplementation may INCREASE the risk of death</a> <br />
              </p>
              <h2>Medical Practice</h2>
              <p>
                <a className="book" href="https://read.amazon.com/kp/embed?asin=B00K5190LE&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_zE-BFb4V28YH6">Incerto</a> <br />
                <a className="article riverrock" href="https://docs.google.com/document/d/1YNEjr8PfzfMcZhx0CmxV7AvXH-evZxLdob696GPpWWk/edit?usp=sharing">Limitations of Evidence-Based Medicine</a><br />
                <a className="article riverrock" href="https://www.quora.com/profile/Zain-Hakeem">Dr. Hakeem's Quora Answers</a> <br />
                <a className="app" href="https://seeing-theory.brown.edu/bayesian-inference/index.html#section1">Bayesian Visualization of Medical Testing</a> <br />
              </p>

              <h2>Behavior Change</h2>
              <p>
                <a className="book" href="https://read.amazon.com/kp/embed?asin=B0030DHPGQ&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_Jc.BFbQH2TG6D">Switch: How to Change Things When Change is Hard</a> <br />
              </p>

            </div>
          </div>
        </div>
        <p className="section__quote">
          “Don’t be pushed by your problems. Be led by your dreams.”<br />
          -Ralph Waldo Emerson
        </p>
        <span className="section__tip"> STUDIES AND REFERENCES</span>
      </div>
    </div>
  );
};


export default Studies;
