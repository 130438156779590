import React, { useState, useEffect } from 'react';
import BasicLongevityComponent from '../../components/basic-longevity-component';


const LongevityBasic = () => {

  return (
    <div className='basic longevity mini-app'>
      <BasicLongevityComponent vo2Start={10}/>
    </div>
  );
}

export default LongevityBasic;
