import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {BarChart, Bar, YAxis, Tooltip, Legend} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    console.log(payload);
    let list = [];
    for (let i=0; i<payload.length-1; i++) {
      list.push(
        <p style={{color:payload[i].fill}}>{payload[i].dataKey}: {payload[i].value} % of total</p>
      );
    }
    return (
      <div className="custom-tooltip">
        {list}
        {/* <p className="label">{`VO2 Max: ${vo2Points} % of total`}</p>
        <p className="label">{`Strength: ${strengthPoints} % of total`}</p>
        <p className="label">{`Smoking: ${smokingPoints} % of total`}</p>
        <p className="label">{`Social: ${socialPoints} % of total`}</p>
        <p className="label">{`BP: ${bpPoints} % of total`}</p> */}
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

const RangeSlider = (props) => {
  const percentage = 100 * (props.value - props.min) / (props.max - props.min)
  const rangerStyle = {
     background: `linear-gradient(90deg, var( - primary-600) 0, var( - orange-500) ${percentage}%, ${props.secondaryBgColor ? props.secondaryBgColor : 'var( - defaut-color)'} ${percentage + 0.1}%)`
   }

return (
 <input className={`range-slider-input ${props.customClasses ? props.customClasses : ''}`} style={rangerStyle} type='range' value={props.value} min={props.min} max={props.max} onChange={props.onChange} disabled={props.disabled} />
 )

}

const BasicLongevityComponent = ({vo2Start}) => {

//   $icterine: #f7f052ff;
// $orange-wheel: #f28123ff;
// $syracuse-red-orange: #d34e24ff;
// $caf-noir: #563f1bff;
// $myrtle-green: #38726cff;
  // let barLength = parseFloat(Math.round((props.inputData.totalLongevityPercentage / 100) * 360));
  const vo2Color = '#A7F2CF';
  const strengthColor = '#479393';
  const smokingColor = '#ECA282';
  const socialColor = '#5465FF';
  const bpColor = '#990D35';
  const bgColor = 'lightgrey';

  const [vo2, setVo2] = useState(vo2Start);
  const [strength, setStrength] = useState(30);
  const [smoking, setSmoking] = useState(0);
  const [social, setSocial] = useState(0);
  const [bp, setBp] = useState(0);
  const smokingStatus = () => {
    switch(smoking) {
      case 5: return 'Never'; break;
      case 4: return 'Rare'; break;
      case 3: return 'Occasional/Social'; break;
      case 2: return 'Often'; break;
      case 1: return '1ppd'; break;
      case 0: return '2ppd'; break;
    }
  };
  const socialStatus = () => {
    switch(social) {
      case 5: return 'Widely Connected'; break;
      case 4: return 'Significantly Connected'; break;
      case 3: return 'Weakly Connected'; break;
      case 2: return 'Poorly Connected'; break;
      case 1: return 'Somewhat Isolated'; break;
      case 0: return 'Severely Isolated'; break;
    }
  };

  const bpStatus = () => {
    if (bp === 0) return 'Low, hypotensive - DANGER';
    if (bp === 1) return 'Hypertension';
    if (bp === 2) return 'Pre-Hypertension';
    if (bp === 3) return 'Normal BP';
  };

  const [vo2Points, setVo2Points] = useState(5);
  const [strengthPoints, setStrengthPoints] = useState(5);
  const [smokingPoints, setSmokingPoints] = useState(5);
  const [socialPoints, setSocialPoints] = useState(5);
  const [bpPoints, setBpPoints] = useState(5);
  
  const vo2Maxpoints = 504;
  const strengthMaxPoints = 300;
  const smokingMaxPoints = 180;
  const socialMaxPoints = 150;
  const bpMaxPoints = 115;


  const totalPossiblePoints = vo2Maxpoints + strengthMaxPoints + smokingMaxPoints + socialMaxPoints + bpMaxPoints;
  const patientTotalPoints = vo2Points + strengthPoints + smokingPoints + socialPoints + bpPoints;
  const totalLongevityPercentage = Math.round((patientTotalPoints / 100)* 100)/1;


  useEffect(() => {
    calcPoints();
  },[vo2, strength, smoking, social, bp]);

  const exponentialRenormalize = (alpha, beta, omega, theta, val) => {
    const a = (omega - theta) / (Math.log10( (alpha+1) / (beta+1) ));
    const d = omega - a * Math.log10(alpha + 1);
    const r = a * Math.log10( val + 1 ) + d;
    return r;
  }

  const calcPoints = () => {     
    setVo2Points(Math.round((exponentialRenormalize(1,100, 1,vo2Maxpoints, vo2)/totalPossiblePoints)*100));
    setStrengthPoints(Math.round((exponentialRenormalize(1,100, 1,strengthMaxPoints, strength)/totalPossiblePoints)*100));
    setSmokingPoints(Math.round((exponentialRenormalize(0,100, 1, smokingMaxPoints, smoking*20)/totalPossiblePoints)*100));
    setSocialPoints(Math.round((exponentialRenormalize(0,100, 1,socialMaxPoints, social*20)/totalPossiblePoints)*100));
    setBpPoints(Math.round((exponentialRenormalize(0,100, 1,bpMaxPoints, bp*25)/totalPossiblePoints)*100));
    console.log(data.VO2);
  }

  let data = [
    {
      name: "Total Longevity",
      VO2: vo2Points,
      Strength: strengthPoints,
      Smoking: smokingPoints,
      Social: socialPoints,
      BP: bpPoints,
      Fill: 100 - (vo2Points + strengthPoints + smokingPoints + socialPoints + bpPoints),
    },
  ];

  const vanishImage = (e) => {
    e.stopPropagation();
    console.log(e.target);
    e.target.classList.remove('show');
  }
  
  return (
    <div className='LongevityBasicComponent' id='LongevityBasicComponent'>
      <img src='./images/longevityBasicGif.gif' className='show overlay' onClick={vanishImage} onDrag={vanishImage} />
      <div className='combo-chart-div'>
        <div className='info-dot'><p>i</p></div>
        <h1 className='finalResult' id='finalResult'>Potential Longevity Claimed: {totalLongevityPercentage}%</h1>
        <BarChart id='combined-barchart'
          width={150}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis dataKey="name" /> */}
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="VO2" stackId="a" fill={vo2Color} />
          <Bar dataKey="Strength" stackId="a" fill={strengthColor} />
          <Bar dataKey="Smoking" stackId="a" fill={smokingColor} />
          <Bar dataKey="Social" stackId="a" fill={socialColor} />
          <Bar dataKey="BP" stackId="a" fill={bpColor} />
          <Bar dataKey="Fill" stackId="a" fill={bgColor} />
        </BarChart>
      </div>
      <div className='input-ranges-div'>
        <div className='range div' id='vo2-range-div'>
        <h2>VO2 Max</h2>
          <RangeSlider
            min='1'
            max='100'
            value={vo2}
            onChange={(e) => { setVo2(+e.target.value) }}
            customClasses='vo2 range input'
          /> 
          <p>{vo2}%ile</p>
        </div>
        <div className='range div' id='strength-range-div'>
        <h2>Strength</h2>
          <RangeSlider
            min='1'
            max='100'
            value={strength}
            onChange={(e) => { setStrength(+e.target.value) }}
            customClasses='strength range input'
          /> 
          <p>{strength}%ile</p>
        </div>
        <div className='range div' id='smoking-range-div'>
        <h2>Smoking</h2>
          <RangeSlider
            min='0'
            max='5'
            value={smoking}
            step="1"
            onChange={(e) => { setSmoking(+e.target.value) }}
            customClasses='smoking range input'
          /> 
          <p>
            {smokingStatus()}
          </p>
        </div>
        <div className='range div' id='social-range-div'>
          <h2>Social<br />Connection</h2>
          <RangeSlider
            min='0'
            max='5'
            step='1'
            value={social}
            onChange={(e) => { setSocial(+e.target.value) }}
            customClasses='social range input'
          /> 
          <p>{socialStatus()}</p>
        </div>
        <div className='range div' id='bp-range-div'>
          <h2>Blood<br />Pressure</h2>
          <RangeSlider
            min='0'
            max='3'
            step='1'
            value={bp}
            onChange={(e) => { setBp(+e.target.value) }}
            customClasses='bp range input'
          /> 
          <p>{bpStatus()}</p>
        </div>
      </div>
    </div>
  );
}

export default BasicLongevityComponent;
