import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';

const Card = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);

  useEffect(() => {
    const startImageAnimation = () => {
      const manTransparent = document.getElementById('man-transparent');
      if (manTransparent) {
        manTransparent.addEventListener('load', (e) => {
          e.target.classList.add('loaded');
        });
      }
    };
    startImageAnimation();
  }, []);

  return (
    <div className="section section--card">
      <Header>DR. ZAIN HAKEEM</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--text">
              <img className="logo-circle" src="/images/logo-colored-slash.png" alt="RiverRock logo" />
              <img className="zh-photo" src="/images/current-headshot.jpg" alt="photos of Dr. Zain Hakeem, founder and physician at RiverRock Medical"
                // srcSet="/images/zh-photo.png 1506w,
                //         /images/zh-photo-281x300.png 281w,
                //         /images/zh-photo-655x698.png 655w,
                //         /images/zh-photo-768.818.png 768w,
                //         /images/zh-photo-961x1024 961w"
                // sizes="(min-width: 768px) 250px, (max-width: 590px) 143px, 25vw"
              />    
              <h1>DR. ZAIN HAKEEM</h1>          
              <a className='contact' href='https://riverrockmedical.com/zainhakeem.vcf'>Download Contact Card</a>
              <p className='tip'>(Click 'Open' after downloading)</p>
              <div className='contact-info'>
                <p className="motto">Contact Info</p>
                <div className="contact-details">
                  <p>Email:</p>                  
                  <a href="mailto:sales@riverrockmedical.com">sales@riverrockmedical.com</a>
                  <p>Tel:</p>
                  <a href="tel:512-213-0700">512-213-0700</a>
                  <p className='map-text'>Map: </p>
                  <a className='map element' href="https://goo.gl/maps/WQuVi8eDFkeuuQCC9">
                    <p className="address">
                      711 W 38th St<br />
                      Suite D1 Room 105<br />
                      Austin, TX 78705<br />
                    </p>
                  </a>
                </div>    
              </div>   
              
              <p className='motto'>Activities</p>
              <div className='activities'>
                <a className='riverrock-home element' href='https://riverrockmedical.com'>Concierge Medical Practice</a>
                <a className='youtube element' target='_blank' href='https://youtube.com/@riverrockmedical'>
                  <img src="/images/youtube_social_icon_dark.png" alt='youtube icon' />
                  RiverRock YouTube Channel
                </a>
                <iframe src="https://www.youtube.com/embed/rO1dapylKgY?si=fP7U4k56Qpe8AHm8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <a className='longevity element' target='_blank' href='https://riverrockmedical.com/longevity'>Longevity App</a>        
                <a className='rxbayes element' target='_blank' href='https://rxbayes.com'>Bayesian Medical Test Interpretation App</a>

              </div>       

            </div>
          </div>
        </div>
        <p className="section__quote">
          “Don’t be pushed by your problems. Be led by your dreams.”<br />
          -Ralph Waldo Emerson
        </p>
        <span className="section__tip">DIGITAL BUSINESS CARD</span>
      </div>
    </div>
  );
};


export default Card;
