import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Menu = () => {
  const location = useLocation();
  const from = location.state?.from.pathname || 'none';

  return (
    <div className="section section--menu">
      <div className="section__content">
        <nav className="nav">
          <Link to={from} className={((from === '/faq') ? 'highlight ' : 'no ') + "btn btn--text btn--close"}>
            <img src='/images/close.svg'></img>
          </Link>
          <img className="nav__logo" src="/images/logo.svg" alt="nav__logo" />
          <span className="motto">Dream-driven healthcare</span>
          <span className='menu menu--label'>Menu</span>
          {/* <a href="https://riverrockmembers.com" className="btn btn--login">
            <span>
              MEMBER<br />
              RENEWALS
            </span>
            <img src="/images/tri-right.svg" alt="" />
          </a> */}
        </nav>

        <div className="content">
          <div className="a-center menu-box-container">
            <div className="box">
              <span className="motto-mobile">Dream-driven healthcare</span>
              <div className='bg-logo'>
                <img className="double-tri" src="/images/double-tri.svg" alt="" />
                <img className="slash" src="/images/slash.svg" alt="" />
              </div>
              <div className='flex f-center big-buttons'>
                <a href="tel:512-213-0700" className='btn btn--text btn-call'>CALL</a>
                <a href="https://go.oncehub.com/NewMemberSetup" className='btn btn--text btn-book'>BOOK</a>
              </div>
              <Link to="/faq" className={((from === '/faq') ? 'highlight ' : 'no ') + "btn btn--text btn--faq"}>
                FAQ
              </Link>
              <Link to="/" className={((from === '/') ? 'highlight ' : 'no ') + "btn btn--image btn--home"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>HOME</span>
              </Link>
              <Link to="/purpose" className={((from === '/purpose') ? 'highlight ' : 'no ') + "btn btn--image btn--purpose"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  OUR VISION
                </span>
              </Link>
              <Link to="/data-assisted" className={((from === '/data-assisted') ? 'highlight ' : 'no ') + "btn btn--image btn--data"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  DATA
                </span>
              </Link>
              <Link to="/services" className={((from === '/services') ? 'highlight ' : 'no ') + "btn btn--image btn--services"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  SERVICES
                </span>
              </Link>
              {/* <Link to="/contact" className={((from === '/contact') ? 'highlight ' : 'no ') + "btn btn--image btn--services"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  CONTACT US
                </span>
              </Link> */}
              {/* <Link to="/values" className={((from === '/values') ? 'highlight ' : 'no ') + "btn btn--image btn--values"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  VALUES
                </span>
              </Link>
              <Link to="/cases" className={((from === '/cases') ? 'highlight ' : 'no ') + "btn btn--image btn--cases"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  CASES
                </span>
              </Link> */}
              <Link to="/about" className={((from === '/about') ? 'highlight ' : 'no ') + "btn btn--image btn--about"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  ABOUT DR.Z
                </span>
              </Link>
              {/* <Link to="/values" className="btn btn--image btn--values">
                <img src="/images/values-bg.svg" alt="" />
                <span>
                  VALUES
                </span>
              </Link> */}
              {/* <Link to="/services" className="btn btn--image btn--strengths">
                <img src="/images/strengths-bg.svg" alt="" />
                <span>
                  SERVICES
                </span>
              </Link> */}
              <Link to="/pricing" className={((from === '/pricing') ? 'highlight ' : 'no ') + "btn btn--image btn--pricing"}>
                <img src="/images/root-outline.svg" alt="" />
                <span>
                  PRICING
                </span>
              </Link>
              <Link to="/contact" className={((from === '/contact') ? 'highlight ' : 'no ') + "btn btn--text btn--contact"}>
                CONTACT US
              </Link>
              <Link to="/faq" className={((from === '/faq') ? 'highlight ' : 'no ') + "btn btn--text btn--faq"}>
                FAQ
              </Link>
              {/* <Link to="/contact" className="btn btn--text btn--contact">
                ABOUT
              </Link> */}
              {/* <Link to="/pricing" className="btn btn--text btn--pricing">
                PRICING
              </Link> */}
            </div>
          </div>
        </div>

        <nav className="nav-mobile">
          {/* <Link to="/contact" className={((from === '/contact') ? 'highlight ' : 'no ') + "btn btn--contact"}>
            CONTACT
          </Link> */}
          <a href="https://www.google.com/maps/dir/30.2645248,-97.7207296/riverrock+medical+clinic/@30.2841579,-97.7503157,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8644b5a735489b15:0x8be31435a92cf332!2m2!1d-97.7403381!2d30.3032886?entry=ttu" className="btn btn--login">
            <span>
              MAP
            </span>
            <img src="/images/tri-right.svg" alt="" />
          </a>
          {/* <a href="tel:512-213-0700" className="btn btn--login">
            <span>
              CALL
            </span>
            <img src="/images/tri-right.svg" alt="" />
          </a> */}
          {/* <a href="https://riverrockmembers.com" className="btn btn--login">
            <span>
              MEMBER<br />
              PAYMENTS
            </span>
            <img src="/images/tri-right.svg" alt="" />
          </a> */}
        </nav>
      </div>
    </div>
  );
}

export default Menu;
