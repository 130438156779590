import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import { HandleScroll } from '../../actions/app';
/**
 * 
 */
const DataAssist = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);
  const navigate = useNavigate()

  const scroll = (e) => {
    let result = HandleScroll(e);
    if (result === 'prev' && prevPath)
      navigate(prevPath);
    if (result === 'next' && nextPath)
      navigate(nextPath);
  }


  return (
    <div className="section section--data" onWheel={scroll}>
      <Header>BASELINE DATA</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (  
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              {/* <img className="purpose-cover" src="/images/purpose-cover.svg" alt="purpose icon" /> */}
              <h5>What Becoming a RiverRock Looks Like</h5>
              <p className='text-content'>RiverRock is founded on a truly different vision of health, and we believe that the core value of RiverRock is in that vision.</p>
              <p className='text-content'>But that philosophy is worthless if it doesn't make us <span className="italic">do things differently</span>. </p>
              <p className='text-content'>It <span className="bold">must</span> have real-world, bread-and-butter, feet-on-the-treadmill consequences for how we end up practicing medicine.</p>
              <p className='text-content'>Here's what that looks like:</p>
              {/* <p className='text-content'>But that vision is intended as a practical philosophy, not a &ldquo;pie in the sky&rdquo; philosophy. The philosophy is worthless if it doesn't make us <span className="c4">do things differently</span>. It <span className="c5">must</span> have real-word, bread-and-butter, feet-on-the-treadmill consequences for how we end up practicing medicine.</p> */}
              <iframe src="https://www.youtube.com/embed/rO1dapylKgY?si=fP7U4k56Qpe8AHm8&autoplay=0&mute=0&loop=0&showinfo=0&controls=1&color=white&rel=0&playsinline=0&enablejsapi=1&playlist=rO1dapylKgY&origin=riverrockmedical.com" title="RiverRock MeaningSpan" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen="allowfullscreen"></iframe>
              {/* ADD YOUTUBE EMBED OF MeaningSpan VIDEO */}
            </div>
            <div className="block--text">
              {/* <h6>The absolute best in standard medical care</h6>  */}
              <h1>First Visit</h1>
              <p className='text-content'>Introduction and establishing fit - thorough history, problem list, and decide how to proceed.</p>
              <h1>Second Visit: <br />Data-Gathering, 6-8 hours</h1>
              <p>&nbsp;</p>
              {/* <p className='text-content'>There are very few other facilities in the world that will gather the same lifespan and healthspan data. And no one else combines it with data on Meaning, Purpose, and Identity, to optimize your MeaningSpan.</p> */}
              {/* <h3>Our standard dataset includes:</h3> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Bloodwork drawn in office</a></h6>
              <p className='text-content'>Our standard labs include:</p>
              <p>
                <ul style={{marginTop: -1+'rem'}}>
                  <li>CBC, CMP, & Magnesium</li>
                  <li>TSH</li>
                  <li>Vitamin D & B-12</li>
                  <li>Iron and ferritin</li>
                  <li>STD Testing</li>
                  <li>A1c</li>
                </ul>
              </p>
              <h6><a href="https://youtube.com/@riverrockmedical">VO2Max + Mitochondrial Health</a></h6>
              <p className='text-content'>The single most important predictor of lifespan and healthspan + FatMax and Fat/Carb Burn Ratio</p>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Detailed Metabolic Assessment</a></h6>
              <p className='text-content'>Including RMR, Carb Burn Ratio, and FATMAX, using the same dataset as VO2Max</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Strength Assessment</a></h6>
              <p className='text-content'>The second-most important predictor of lifespan and healthspan - we'll take a trip to the gym</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Pulmonary Function Testing</a></h6>
              <p className='text-content'>In-office Spirometry</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Baseline Echo, EKG, and Cognitive Function</a></h6>
              <p className='text-content'>We think ahead - having a baseline can be crucial.</p>
              {/* <h6><a href="https://youtube.com/@riverrockmedical">Cognitive Function Baseline</a></h6>
              <p className='text-content'>Through Creyos - may not be important now, but it's good to have a comparison in case of concussion or aging.</p> */}
              <h6><a href="https://youtube.com/@riverrockmedical">Home Telehealth Kit Provided</a></h6>
              <p className='text-content'>For accurate BP, heart rate, pulse ox, etc. at home</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Personality Inventory Testing</a></h6>
              <p className='text-content'>Big-5, Enneagram</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Meaning and Purpose</a></h6>
              <p className='text-content'>Multiple hours of goal-setting conversation; motivators; evaluation of habits; root cause analysis to determine directions of growth</p>

              {/* <h6><a href="https://youtube.com/@riverrockmedical">1-hr Office Visits & No Waiting</a></h6>
              <p className='text-content'>Phone and Video available 30-60min</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Most Bloodwork drawn in office</a></h6>
              <h6><a href="https://youtube.com/@riverrockmedical">Health article review & topic research</a></h6>
              <p className='text-content'></p>
              <h6><a href="https://youtube.com/@riverrockmedical">EEG Neurofeedback with Myndlift</a></h6>
              <p className='text-content'>Brainwave Assessment and biofeedback, for anxiety, focus, or cognitive and performance enhancement</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Vagal training</a></h6>
              <p className='text-content'>Including Resonance Frequency Breathing, among other methods</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Rapid Online Specialist Access</a></h6>
              <p className='text-content'>Through RubiconMD</p>

              <h6><a href="https://youtube.com/@riverrockmedical">Hypnosis</a></h6>
              <p className='text-content'></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Osteopathic Manipulation</a></h6>
              <p className='text-content'>Including craniosacral as well as "crack/pop" techniques</p>
              <h6><a href="https://youtube.com/@riverrockmedical">Trigger Point Injections</a></h6>
              <p className='text-content'>AKA 'wet needling'</p>

              <h6><a href="https://youtube.com/@riverrockmedical">Platelet-Rich Plasma</a></h6>
              <p className='text-content'>PRP for hair loss, aesthetics, or joint/tendon injuries and issues</p>
              <h6><a href="https://youtube.com/@riverrockmedical">IV therapies and fluids as needed</a></h6>
              <p className='text-content'></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Bayesian Diagnostics and Risk Assessment</a></h6>
              <p className='text-content'>I made the app for it - <a href="https://rxbayes.com">Rx-Bayes</a></p>
              <h6><a href="https://youtube.com/@riverrockmedical">Patient Task Tracking</a></h6>
              <p className='text-content'>We'll help you keep track of what screening, tests, and followups you need</p>
              <h6><a href="https://youtube.com/@riverrockmedical">4 Months of Intensive Coaching</a></h6>
              <p className='text-content'>For when you're ready to make lifestyle changes, but need some encouragement</p> */}
              <h2>That's just our baseline. <br />We offer many more services as needed. &darr;</h2>
            </div>
          </div>
        </div>
        <p className="section__quote">
          “Nothing ever becomes real till it is experienced”<br />
          -John Keats<br />
        </p>
        <span className="section__tip">
          BASELINE DATA
        </span>
      </div>
    </div>
  );
};

export default DataAssist;
