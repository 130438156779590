import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import { HandleScroll } from '../../actions/app';
import BasicLongevityComponent from '../../components/basic-longevity-component';

const Patients = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);
  const navigate = useNavigate();

  useEffect(() => {
    const startImageAnimation = () => {
      const manTransparent = document.getElementById('man-transparent');
      if (manTransparent) {
        manTransparent.addEventListener('load', (e) => {
          e.target.classList.add('loaded');
        });
      }
    };
    startImageAnimation();
  }, []);

  const scroll = (e) => {
    let result = HandleScroll(e);
    if (result === 'prev' && prevPath)
      navigate(prevPath);
    if (result === 'next' && nextPath)
      navigate(nextPath);
  }

  return (
    <div className="section section--patients" onWheel={scroll}>
      <Header>YOU</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              <img
                className="man-transparent"
                id="man-transparent"
                src="/images/man-transparent.png"
                alt="Bearded Man in Profile with juxtaposed tree silhouette"
                srcSet="/images/man-transparent.png 3096w,
                          /images/man-transparent-291x300.png 291w,
                          /images/man-transparent-655x676.png 655w,
                          /images/man-transparent-768x793.png 768w,
                          /images/man-transparent-992x1024.png 992w"
                sizes="(min-width: 1160px) 510px, 43.5vw"
              />
              <img className="tri-down-outline" src="/images/tri-down-outline.svg" alt="our patients icon" />
            </div>
            <div className="block--text">
              <img className="logo-text" src="/images/logo-text.svg" alt="text based logo" />
              <p className="motto">Dream-driven healthcare</p>
              <p className="text-content">
                You have big dreams. Those dreams aren't just talk; your dreams are your driving force. <br /><br />
                You eat clean, workout hard, but sometimes you wonder what to make of conflicting studies; you wonder how to get the next edge, make the next upgrade. <br /><br />
                {/* You want to partner with a doctor to improve sleep, shave down your race time, and figure out how to enjoy your great grandkids; but somehow you always seem to know more than your doctor.<br /><br /> */}
                Since it's hard to improve what you don't measure, you think surely there's a way to take targeted actions toward your health goals, using the latest tech. <br /><br />
                {/* You're biohacking, meditating, and your doctor just looks at your bloodwork and says "it's fine, you're healthy". You're trying to solve challenges your doctor can't even see. <br /><br /> */}
                You've noticed that bloodwork mostly just comes out normal, without actionable insights. You wonder if the optimizations you're looking for aren't in your blood. But then, where are they? <br /><br />
                You know that feeling good comes from being good, and being good comes from doing good, every day. <br /><br />
                You're not moving away from your problems - your dreams are driving you toward excellence.<br /><br />
                You belong with us - You're a RiverRock. <Link to="/services">Learn More &rarr;</Link>.
              </p>
            </div>
          </div>
        </div>
        <p className="section__quote">
          “Virtues are formed in man by his doing the actions ... <br />the good of man is a working of the soul, in the way of excellence”<br />
          -Aristotle
        </p>
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image longevity">
              <BasicLongevityComponent vo2Start={10}/>
            </div>
            <div className="block--text">
              {/* <img className="logo-text" src="/images/logo-text.svg" alt="text based logo" /> */}
              {/* <p className="motto">Dream-driven healthcare</p> */}
              <h1>Longevity</h1>
              <p className="text-content">
                There are only five major longevity factors supported by current science:
                {/* Currently, there is no real scientific evidence for anything beyond these 5 factors: <br /> */}
                <ol>
                  <li>VO2 Max <span className='longevity power'>(5x power)</span></li>
                  <li>Strength <span className='longevity power'>(3x power)</span></li>
                  <li>Smoking (minimize) <span className='longevity power'>(1.8x power)</span></li>
                  <li>Social Connectedness <span className='longevity power'>(1.5x power)</span></li>
                  <li>Blood Pressure <span className='longevity power'>(1.15x power)</span></li>
                </ol>

                In addition to diagnosing and treating any active problems, we begin by assessing these 5 factors, providing you with meaningful metrics and an actionable plan for claiming more of your longevity potential.<br /> <br />
                Many of our patients get pretty close to 100%. What then? <br /> <br />
                Longevity itself is only half the equation - RiverRock is unique in actively and algorithmically pursuing the other half: Meaning. <br />
              </p>
            </div>
          </div>
        </div>
        <p className="section__quote">
          Longevity Alone is Not Enough.
        </p>        
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              <img
                className="meaningspan"
                id="meaningspan"
                src="/images/meaningspan-with-writing.gif"
                alt="Explanation of MeaningSpan: combining longevity with depth and meaning, optimizing the combination"
                // srcSet="/images/man-transparent.png 3096w,
                //           /images/man-transparent-291x300.png 291w,
                //           /images/man-transparent-655x676.png 655w,
                //           /images/man-transparent-768x793.png 768w,
                //           /images/man-transparent-992x1024.png 992w"
                // sizes="(min-width: 1160px) 510px, 43.5vw"
              />
              {/* <img className="tri-down-outline" src="/images/tri-down-outline.svg" alt="our patients icon" /> */}
            </div>
            <div className="block--text">
              {/* <img className="logo-text" src="/images/logo-text.svg" alt="text based logo" /> */}
              {/* <p className="motto">Dream-driven healthcare</p> */}
              <h1>MeaningSpan</h1>
              <p className="text-content">
                MeaningSpan is the combination of both longevity and <span className='meaningspan depth'>depth</span>. <br /> <br />
                When we say "Dream-Driven", we mean your deepest dreams: <br /> Dreams of Purpose and Fulfillment, Meaning and Identity. <br /> <br />
                We classify those dreams in 10 categories:
                <ol>
                  <li>Passion</li>
                  <li>Family</li>
                  <li>Belonging</li>
                  <li>Growth</li>
                  <li>Self-Expression</li>
                  <li>Discovery</li>
                  <li>Legacy</li>
                  <li>Experience</li>
                  <li>Victory</li>
                  <li>Spirituality</li>
                </ol>
                We'll explore which ones are most meaningful for you, personally, and then create a strategy for progressively deepening your sense of meaning through dedicated expansion of your chosen areas.<br /> <br />
                {/* You're a RiverRock - <Link to="/services">Become a member</Link>. */}
              </p>
            </div>
          </div>
        </div> 
        <p className="section__quote">
          “Don’t be pushed by your problems. Be led by your dreams.”<br />
          -Ralph Waldo Emerson
        </p>   
        <span className="section__tip">OUR PATIENTS</span>
        <div className='click-here'>
          <img className='click-here' src=' /images/curved-arrow-icon.png' />
          <p className='click-here'>Click to learn about our vision</p>
        </div>
      </div>
    </div>
  );
};

export default Patients;
