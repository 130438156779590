import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition, SwitchTransition } from 'react-transition-group-react-18';

import Menu from './menu';
import Patients from './patients';
import Purpose from './purpose';
import DataAssist from './data-assisted';
import Services from './services';
import Cases from './cases';
import Values from './values';
import Pricing from './pricing';
import About from './about';
// import Strengths from './strengths';
// import Benefits from './benefits';
import Faq from './faq';

import { getPrevPath } from './paths';

const isMobile = (window.innerWidth < 768);
let transitionSpeed = (isMobile) ? 200 : 400;

const getTransitionClassName = (locationPathname, prevPathname) => {

  if (locationPathname === prevPathname) {
    return 'slide-left';
  } else if (prevPathname === '/menu') {
    if (isMobile) {
      return 'slide-left';
    }
    return 'slide-right';
  } else if (locationPathname === '/menu') {
    if (isMobile) {
      return 'slide-right';
    }
    return 'slide-left';
  } else if (locationPathname === getPrevPath(prevPathname)) {
    return 'slide-down';
  } else {
    return 'slide-up';
  }
};

const AnimatedRoute = () => {
  const location = useLocation();
  const [animatedRouteState, setAnimatedRouteState] = useState({
    transitionClassName: '',
    pathname: location.pathname,
  });

  useEffect(() => {
    document.documentElement.style.setProperty('--transition-speed', `${transitionSpeed}ms`);
    const transitionClassName = getTransitionClassName(location.pathname, animatedRouteState.pathname);
    setAnimatedRouteState({
      transitionClassName,
      pathname: location.pathname,
    });
  }, [location.pathname]);

  return (
    <TransitionGroup className={animatedRouteState.transitionClassName}>
      <SwitchTransition>
        <CSSTransition
          timeout={transitionSpeed}
          classNames={"page"}
          key={location.key}
        >
          <Routes location={location}>
            <Route path="/menu" element={<Menu />} />
            <Route path="/" element={<Patients />} />
            <Route path="/purpose" element={<Purpose />} />
            <Route path="/services" element={<Services />} />
            <Route path="/data-assisted" element={<DataAssist />} />
            <Route path="/pricing" element={<Pricing />} />
            {/* <Route path="/strengths" element={<Strengths />} /> */}
            <Route path="/cases" element={<Cases />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/benefits" element={<Benefits />} /> */}
            {/* <Route path="/values" element={<Values />} /> */}
            <Route path="/faq" element={<Faq />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </TransitionGroup>
  );
};

export default AnimatedRoute;
