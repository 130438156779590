export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const setIsMobile = (isMobile) => ({
  type: SET_IS_MOBILE,
  payload: isMobile,
});


export const HandleScroll = (event) => {
  const scrollTop = window.scrollY;
  const clientHeight = window.innerHeight;
  const scrollHeight = window.document.scrollingElement.scrollHeight;

  if (event.deltaY < 0) {
    //scrolling up
    if (scrollTop === 0) {
      return 'scroll';
      return 'prev';
    }
  }
  if (event.deltaY > 0 ) {
    //scrolling down
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
      return 'scroll';
      return 'next';
    }
  }
  
  return 'scroll';
};
