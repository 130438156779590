import React from 'react';
import { Link } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

const CustomLabel = (props) => {
  // let name = props.name;
  let fillColor = '#000';
  if (props.name === 'Total Longevity Percentage') {
    fillColor = 'transparent';
  }
  let number = props.index;
  return (
      <text className='LineChartLabel' id={props.name + 'Id'} x={props.cx} y={props.cy+63.5+ + 12.5*number} fill="currentColor" stroke='1px black'>{props.name}</text>
  );
}

const ComponentGraph = (props) => {
  let refLine = 0;
  let refHeight = 0;
  const name = props.name;
  const data = props.inputData;

  for (let i=0; i<data.length; i++) {
    if (data[i].current) {
      refLine = data[i].value;
      refHeight = data[i].points;
    }
  }
  
  const style = {
    top: 0,
    lineHeight: "24px",
  };
  
  return (
    <div className='propsNameGraph' id='propsNameGraph'>
      {/* <h1 className='propsNameTitle' id='propsNameTitle'>{name}</h1> */}
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="value" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine x={refLine} stroke="red" label={name + ': ' + refLine} />
          {/* <ReferenceLine y={refHeight} label={"Current: " + refHeight} stroke="red" /> */}
          {/* <Line type="monotone" dataKey="value" stroke="#8884d8" /> */}
          <Line type="monotone" dataKey="points" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ComponentGraph;
