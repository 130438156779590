import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPrevPath, getNextPath } from '../paths';
import { MenuButton, Header } from '../../components';
import { HandleScroll } from '../../actions/app';
/**
 * 
 */
const Contact = () => {
  const location = useLocation();
  const prevPath = getPrevPath(location.pathname);
  const nextPath = getNextPath(location.pathname);
  const navigate = useNavigate()

  const scroll = (e) => {
    let result = HandleScroll(e);
    if (result === 'prev' && prevPath)
      navigate(prevPath);
    if (result === 'next' && nextPath)
      navigate(nextPath);
  }


  return (
    <div className="section section--contact" onWheel={scroll}>
      <Header>CONTACT US</Header>
      <div className="section__content container">
        {!!prevPath && (
          <Link to={prevPath} className="goto-prev">
            <img src="/images/left-arrow.svg" alt="link to previous page" />
          </Link>
        )}
        {!!nextPath && (  
          <Link to={nextPath} className="goto-next">
            <img src="/images/right-arrow.svg" alt="link to next page" />
          </Link>
        )}
        <MenuButton />
        <div className="content-wrapper">
          <div className="content">
            <div className="block--image">
              <h2>Office Location</h2>
              <iframe 
                title='googleMap'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13780.243849893477!2d-97.74419888360595!3d30.292326082808575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b5a735489b15%3A0x8be31435a92cf332!2sRiverRock%20Medical%20Clinic!5e0!3m2!1sen!2sus!4v1724027071015!5m2!1sen!2sus" 
                frameborder="0"
                style={{ 
                  width: "100%",
                  height: 450,
                  border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"                
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
              <p><a href="https://www.google.com/maps/dir/30.2645248,-97.7207296/riverrock+medical+clinic/@30.2841579,-97.7503157,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8644b5a735489b15:0x8be31435a92cf332!2m2!1d-97.7403381!2d30.3032886?entry=ttu" className="map-link">711 W 38th St<br />STE D1, Rm 105<br />Austin, TX 78705</a></p>
            </div>
            <div className="block--text">
              <h5>Questions?</h5>
              <a className="booking" href="https://calendar.app.google/fhRxQFmHCx2H3gKo6">Book a Free Sales Call</a>
              <h5>Ready to Get Started?</h5>
              <a className="booking" href="https://riverrock.pro/NewMemberSetup">Book Your Initial Visit&nbsp;&rarr;&nbsp;</a>
              <h5>Call</h5>
              <p className="text-content"><a href="tel:+15122130700"><img className='icon' src='./images/call-icon.svg' style={{verticalAlign: 'middle'}}/>512.213.0700</a></p>
              <h5>Email</h5>
              <p className='text-content'><a href="mailto:sales@riverrockmedical.com"><img className='icon' src='./images/mail-icon.svg' style={{verticalAlign: 'middle'}}/>sales@riverrockmedical.com</a><br /></p>
            </div>
          </div>
        </div>
        <p className="section__quote">
          “It is not daily increase, but daily decrease.<br />
          Hack away at the unessential”<br />
          -Bruce Lee<br />
        </p>
        <span className="section__tip">
          CONTACT US
        </span>
      </div>
    </div>
  );
};

export default Contact;
